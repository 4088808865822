import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";

import { Header } from "../components/Header/Header";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import logoWhite from "../images/logo-white.png";
import { navigate } from "gatsby";
import { Link } from "gatsby";
import { Footer } from "../components/Footer/Footer";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/rootReducer";

const Contact = ({ location }: any) => {
  const userName =
    typeof window !== "undefined" && localStorage.getItem("name");
  const [signed, setSigned] = useState(false);

  const loginElement: any = useSelector((state: RootState) => state.loginSlice);

  const [bzns, setBzns] = useState(false);

  useEffect(() => {
    if (loginElement.items.status) {
      setSigned(true);
    }
  }, [loginElement]);

  useEffect(() => {
    if (location.state !== null) {
      const { editStatus } = location.state;
      if (location.state.id === "bzn") {
        setBzns(true);
      }
    }
  }, [location]);

  return (
    <Layout signed={signed}>
      <div className="bg-[#f2f2f2]">
        <div className="mb-[40px]">
          {/* <Header isSignIn={signed} /> */}

          <section className="container px-[15px] mx-auto pt-[40px] lg:pt-[60px] pb-[40px] lg:pb-0">
            <div className="w-full">
              <div className="col-span-8">
                {bzns ? (
                  <iframe
                    src="https://umgcventures.kustomer.help/contact/contact-us-H1MrxQ0wj"
                    className="w-full h-[1030px] md:h-[1030px] lg:h-[1010px] rounded-2xl"
                    frameBorder="0"
                  ></iframe>
                ) : (
                  <iframe
                    src="https://umgcventures.kustomer.help/contact/contact-us-normal-ry_sSmvis"
                    className="w-full h-[1030px] md:h-[1030px] lg:h-[1010px] rounded-2xl"
                    frameBorder="0"
                  ></iframe>
                )}

                {/* <div className="rounded-md bg-white shadow-lg p-[15px] md:p-[30px] pb-10">
                  <div className="md:col-span-12 mb-5">
                    <div className="flex items-center justify-between">
                      <p className="ff-cg--semibold">First Name*</p>
                    </div>
                    <input 
                      className="placeholder:text-slate-400 focus:outline-none w-full bg-slate-50 p-4 mt-2 rounded-2xl ff-cg--medium"
                      name="firstName"
                      type="text"
                    />
                  </div>
                  <div className="md:col-span-12 mb-5">
                    <div className="flex items-center justify-between">
                      <p className="ff-cg--semibold">Company*</p>
                    </div>
                    <input 
                      className="placeholder:text-slate-400 focus:outline-none w-full bg-slate-50 p-4 mt-2 rounded-2xl ff-cg--medium"
                      name="firstName"
                      type="text"
                    />
                  </div>
                  <div className="md:col-span-12 mb-5">
                    <div className="flex items-center justify-between">
                      <p className="ff-cg--semibold">Your Email Address*</p>
                    </div>
                    <input 
                      className="placeholder:text-slate-400 focus:outline-none w-full bg-slate-50 p-4 mt-2 rounded-2xl ff-cg--medium"
                      name="firstName"
                      type="text"
                    />
                  </div>
                  <div className="md:col-span-12 mb-5">
                    <div className="flex items-center justify-between">
                      <p className="ff-cg--semibold">Phone Number*</p>
                    </div>
                    <input 
                      className="placeholder:text-slate-400 focus:outline-none w-full bg-slate-50 p-4 mt-2 rounded-2xl ff-cg--medium"
                      name="firstName"
                      type="text"
                    />
                  </div>
                  <div className="md:col-span-12 mb-5">
                    <div className="flex items-center justify-between">
                      <p className="ff-cg--semibold">How can we help?</p>
                    </div>
                    <textarea 
                      className="placeholder:text-slate-400 focus:outline-none w-full bg-slate-50 p-4 mt-2 rounded-2xl ff-cg--medium"
                      name="firstName"
                    />
                  </div>
                  <div className="md:col-span-12">
                    <button className="w-full lg:w-fit flex items-center justify-between bg-[#fdbf38] py-[14px] px-[16px] rounded-2xl mt-[30px]">
                      <span className="ff-cg--semibold px-10">Send</span>
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </section>

          <section className="container px-[15px] mx-auto pt-[40px] lg:pt-[60px] pb-[40px] lg:pb-0">
            <div className="bg-white rounded-2xl p-[30px] w-full">
              <div className="col-span-8 flex justify-center items-center">
                <EnvelopeIcon className="w-10 h-10 mr-4" />
                <p className="text-[20px]">
                  If you are an existing customer and need help, please contact
                  our{" "}
                  <Link
                    to="/support"
                    className="ff-cg--semibold text-[#da1a32] underline"
                  >
                    Support Team
                  </Link>
                </p>
              </div>
            </div>
          </section>

          {/* footer */}
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
export { Head } from "../components/Layout/Head";
